body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* table css */
.pagination .page-item.active .page-link {
  background: #1a2131 !important;
}
table thead:first-child {
  background: #1a2131;
  color: white;
}
table thead:last-child {
  display: none !important;
}

#submit {
  width: 100%;
  margin-top : 20px;
  text-transform: none;
  background: #1a2131;
  padding: 10px;
  min-width: 100px;
  border-radius: 4px;
  color: white;
  border: 0;
}

/* .css-1ygcj2i-MuiTableCell-root {
  color: white !important;
} */

/* .css-1ndpvdd-MuiTableCell-root {
  color: white !important;
} */

.ant-popover {
  z-index: 9999999999999 !important;
  width: 150px !important;
  /* color: black !important; */
  cursor: pointer !important;
}

.PhoneInput {
  width: 100% !important;
  padding: inherit !important;
}
.PhoneInputInput {
  padding: 16px 14px !important;
}
